import React, { Component, Fragment } from "react";
import { TopBarComponent } from "./TopBarComponent";
import { SideBarComponent } from "./SideBarComponent";

export class AuthorisedLayout extends Component {
  static displayName = AuthorisedLayout.name;

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
  }
  render() {
    return (
      <Fragment>
        <div className="wrapper">          
          <TopBarComponent />
          <SideBarComponent />
          <div className="content-wrapper">{this.props.children}</div>
        </div>
      </Fragment>
    );
  }
}